<template>
  <v-container>
    <v-row class="d-flex justify-center">
      <v-col cols="12" md="8" sm="10">
        <v-card v-if="loading" elevation="3">
          <v-card-title></v-card-title>
          <v-card-text>
            <v-sheet>
              <v-skeleton-loader
                type="card-heading, divider, list-item-three-line, list-item-three-line, divider, list-item-three-line, list-item-three-line, divider, list-item-three-line, actions"
              ></v-skeleton-loader>
            </v-sheet>
          </v-card-text>
        </v-card>
        <user-form v-else v-model="formData" @input="handleSubmitForm"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapActions} from "vuex";
import UserForm from "@/components/forms/UserForm"
import EventBus from "@/util/eventbus";

export default {
  name: "UserCreate",
  components: { UserForm },
  data: () => ({
    loading: true,
    formData: {
      firstName: null,
      lastName: null,
      phone: null,
      email: null,
      identification: null,
      roles: null,
      picture: null,
    },
  }),
  async created() {
    const param = this.$route.params['id']

    if (param) {
      this.$store.commit('LOADER', true)
      this.fetchEmployee(param)
        .then(user => {
          this.formData = Object.assign({}, user)
          this.loading = false
        })
        .finally(() => {
          this.$store.commit('LOADER', false)
          this.loading = false
        });
    } else {
      this.loading = false
    }
  },
  methods: {
    ...mapActions('employee', ['fetchEmployee', 'createEmployee', 'editEmployee']),
    handleSubmitForm(data) {
      if (data.id === undefined) {
        this.createEmployee(data)
          .then(() => {
            EventBus.$emit("SHOW_SNACKBAR", {
              text: 'El trabajador ha sido creado exitosamente',
              color: 'success',
            });
            this.$router.push({ name: 'admin.personal.list' })
          })
          .catch((err) => {
            console.log(err)
          });
      } else {
        this.editEmployee(data)
          .then(() => {
            EventBus.$emit("SHOW_SNACKBAR", {
              text: 'los cambios han sido guardados',
              color: 'success',
            });
            this.$router.push({ name: 'admin.personal.list' })
          })
          .catch((err) => {
            console.log(err)
          });
      }
    },

  },
};
</script>

<style scoped></style>
