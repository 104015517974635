<template>
  <v-card elevation="3">
    <v-card-title class="primary white--text">Nuevo usuario</v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="validation.valid" lazy-validation>
        <form-section title="Información personal"/>
        <div class="d-flex flex-column flex-md-row">
          <v-text-field
            v-model="value.firstName"
            :rules="validation.rules.firstNameRules"
            class="mr-md-2"
            label="Nombre(s)"
            outlined
            required
          />
          <v-text-field
            v-model="value.lastName"
            :rules="validation.rules.lastNameRules"
            label="Apellidos"
            outlined
            required
          />
        </div>
        <div class="d-flex flex-column flex-md-row">
          <v-text-field
            v-model="value.phone"
            :rules="validation.rules.phoneRules"
            append-icon="mdi-phone"
            class="mr-md-2"
            label="Teléfono"
            outlined
            required
          />
          <v-text-field
            v-model="value.email"
            :rules="validation.rules.emailRules"
            append-icon="mdi-email"
            label="Correo electrónico"
            outlined
            required
          />
        </div>
        <div class="d-flex flex-column flex-md-row">
          <v-col class="pa-0" cols="12" md="6">
            <v-text-field
              v-model="value.identification"
              :readonly="value.id !== undefined"
              :rules="validation.rules.identificationRules"
              append-icon="mdi-card-account-details"
              class="mr-md-1"
              label="Identificación"
              outlined
              required
            />
          </v-col>
          <v-file-input
            v-model="avatar"
            :rules="validation.rules.avatarRules"
            label="Foto Perfil"
            prep
            small-chips
            solo
            @change="handleFile"
          />
        </div>
        <v-select
          v-model="value.roles"
          :items="roleItems"
          :rules="validation.rules.rolesRules"
          deletable-chips
          hide-details
          item-text="description"
          item-value="value"
          label="Roles"
          multiple
          outlined
          placeholder="Ingrese los roles del usuario"
          small-chips
        />
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn outlined @click="handleCancelForm">
        Cancelar
      </v-btn>
      <v-spacer/>
      <v-btn
        :disabled="!validation.valid"
        color="primary"
        tile
        @click="handleSubmitForm"
      >
        Enviar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {EMAIL} from "@/util/regex";
import FormSection from '@/components/widgets/Section';

export default {
  name: "UserForm",
  components: { FormSection },
  props: {
    value: {
      type: Object,
      required: true
    },
  },
  data: () => ({
    showPassword: false,
    showConfirmation: false,
    avatar: null,
    passwordConfirm: null,
    roleItems: [
      { value: "Employee", description: "Trabajador" },
      { value: "Supervisor", description: "Supervisor" },
      { value: "Admin", description: "Administrador" },
    ],
    validation: {
      valid: false,
      rules: {
        firstNameRules: [
          (v) => !!v || "El nombre es requerido",
          (v) =>
            (v && v.length >= 5) ||
            "El nombre debe tener al menos 5 caracteres",
        ],
        lastNameRules: [
          (v) => !!v || "Los apellidos son requeridos",
          (v) =>
            (v && v.length >= 5) ||
            "Los apellidos deben tener al menos 5 caracteres",
        ],
        phoneRules: [(v) => !!v || "El número de teléfono es requerido"],
        emailRules: [
          (v) => !!v || "Se requiere un correo electrónico",
          (v) => EMAIL.test(v) || "Ingrese un correo electrónico válido",
        ],
        identificationRules: [
          (v) => !!v || "Ingrese la identificación del usuario",
          (v) =>
            (v && v.length >= 10) ||
            "La identificación debe tener 10 caracteres",
        ],
        rolesRules: [(v) => !!v || "Seleccione un rol para el nuevo usuario"],
        avatarRules: [
          (v) =>
            !v ||
            v.size < 2000000 ||
            "La foto de perfil debe tener menos de 2 MB!",
        ],
      },
    }
  }),
  methods: {
    handleCancelForm() {
      this.$router.go(-1);
    },
    handleSubmitForm() {
      if (!this.$refs.form.validate()) return;

      this.$emit('input', this.value)
    },
    async handleFile(file) {
      if (!file) return;
      this.getBase64(file);
    },
    getBase64(file) {
      const reader = new FileReader();
      let vm = this;
      reader.readAsDataURL(file);
      reader.onload = function () {
        vm.value.picture = reader.result;
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
    },
  }
}
</script>

<style scoped>

</style>